<template>
  <div>
    <v-container fluid>
      <div class="main">

        <v-overlay :value="isLoading"
                   color="white"
                   opacity="0.5">
          <v-progress-circular
              color="primary"
              indeterminate
              size="50"
          ></v-progress-circular>
        </v-overlay>

        <v-row align="center" justify="center">
          <v-col cols="12" lg="10">

            <v-card class="shadow rounded-lg sticky-card">

              <v-card-title>
                Nouvelle campagne
                <v-spacer/>
                <v-btn icon color="gifty" to="/opus/campaigns" exact>
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle>
                Tous les champs indiqués avec une étoile <span class="red--text">(*)</span> sont obligatoires.
              </v-card-subtitle>

              <v-divider/>

              <v-card-text class="pa-6">

                <v-stepper v-model="tab" flat outlined non-linear alt-labels >
                  <v-stepper-header>

                    <v-stepper-step editable step="1"  color="gifty">
                     <span class="text-no-wrap">
                         Info & Plannification
                     </span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step editable step="2"  color="gifty">
                      Enquêtes
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3" editable  color="gifty">
                      Tâches
                    </v-stepper-step>

                  </v-stepper-header>
                </v-stepper>

              </v-card-text>
            </v-card>

            <v-card class="shadow rounded-lg mt-3" v-if="tab === '1'">
              <v-card-text class="px-4 px-lg-15 py-10">
                <InfoPlanning :campaign="campaign"/>
              </v-card-text>
            </v-card>

            <Investigations v-if="tab === '2'" :campaign="campaign"/>

            <Tasks v-if="tab === '3'"
                   :campaign="campaign"
                   :answer_types="answer_types"
                   :question_types="question_types"/>

            <div class="d-flex align-center justify-space-between mt-3">

              <v-btn text class="rounded-lg"
                     v-if="tab === '2'"
                     @click="[tab = '1',$vuetify.goTo(0)]">
                <v-icon left>mdi-arrow-left</v-icon>
                Précédent
              </v-btn>

              <v-btn text class="rounded-lg"
                     v-if="tab === '3'"
                     @click="[tab = '2',$vuetify.goTo(0)]">
                <v-icon left>mdi-arrow-left</v-icon>
                Précédent
              </v-btn>

              <v-spacer/>

              <v-btn color="gifty" depressed dark class="rounded-lg"
                     v-if="tab === '1'"
                     @click="[tab = '2',$vuetify.goTo(0)]">
                Suivant
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>

              <v-btn color="gifty" depressed dark class="rounded-lg"
                     v-if="tab === '2'"
                     @click="[tab = '3',$vuetify.goTo(0)]">
                Suivant
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>

              <v-btn color="gifty" depressed dark class="rounded-lg"
                     :disabled="isLoading"
                     v-if="tab === '3'"
                     @click="saveCampaign">
                Sauvgarder
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>

            </div>

          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import InfoPlanning from "@/views/opus/campaigns/create/components/InfoPlanning";
import Investigations from "@/views/opus/campaigns/create/components/Investigations";
import Tasks from "@/views/opus/campaigns/create/components/Tasks";
import {HTTP} from "@/http-common";

export default {
  components: {Tasks, Investigations, InfoPlanning},
  data() {
    return {
      tab: "1",
      campaign: {
        name: {
          fr: '',
          ar: '',
        },
        start_date: '',
        end_date: '',
        description: {
          fr: '',
          ar: '',
        },
        images: [],
        is_active: true,
        investigations: [
          {
            name: {
              fr: '',
              ar: '',
            },
            start_date: '',
            end_date: '',
            description: {
              fr: '',
              ar: '',
            },
            images: [],
            winning_points: '',
            for_all_salepoints: false,
            client_salepoint_ids: [],
            is_active: true,
            tasks: []
          }
        ]
      },
      answer_types: [],
      question_types: [],

      errors: [],
      isLoading: false,
    }
  },
  methods: {
    getTypes() {
      this.isLoading = true
      HTTP.get('/v1/opus/campaigns/types').then((res) => {
        this.answer_types = res.data.data.answer_types
        this.question_types = res.data.data.question_types
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    saveCampaign() {
      this.isLoading = true
      this.errors = []
      HTTP.post('/v1/opus/campaigns/store', this.campaign).then(() => {
        this.isLoading = false
        this.$successMessage = "La campagne a été enregistrée avec succès."
        this.$router.push("/opus/campaigns")
      }).catch(err => {
        this.isLoading = false
        this.errors = err.response.data.errors
        console.log(err)
      })
    },
  },
  created() {
    this.getTypes()
  }
}
</script>

<style scoped>
.sticky-card {
  position: sticky;
  top: -50px;
  z-index: 1
}
</style>